<template>
    <div class="d-block d-lg-none">
        <div class="row">
            <div class="col-8 col-lg-10">
                <p class="messagingMobile" style="margin-left: 20px;">MessagingMobile</p>
            </div>
            <div class="col-4 col-lg-2">
                <p class="closeButton" @click="GoSetting()"><font-awesome-icon :icon="['fas','times']" /></p>
            </div>
        </div>
        <div class="hr-border"></div>
    </div>
    <div class="mobileStyleDoctor">
    <div class="row">
      <div class="col-12">
          <label>Posts</label>
      </div>
      <div class="col-12 form-group">
          <div class="form-check form-check">
              <input class="form-check-input" type="checkbox" name="post1" id="post1" value="post1">
              <label class="form-check-label" for="post1">Publish my posts to all the doctors groups</label>
          </div>
          <div class="form-check form-check">
              <input class="form-check-input" type="checkbox" name="post1" id="post1" value="post1">
              <label class="form-check-label" for="post1">Publish my posts to all groups that I am part of</label>
          </div>
          <div class="form-check form-check">
              <input class="form-check-input" type="checkbox" name="post1" id="post1" value="post1">
              <label class="form-check-label" for="post1">Allow public who have not signed up on RxIx to view my posts  </label>
          </div>
          <div class="form-check form-check">
              <input class="form-check-input" type="checkbox" name="post2" id="post2" value="post2">
              <label class="form-check-label" for="post2">Allow RxIx members to comment on my posts</label>
          </div>
      </div>
  </div>
  <div class="row mt-3">
      <div class="col-12">
          <label>Messages and notifications</label>
      </div>
      <div class="col-12 form-group">
          <div class="form-check form-check">
              <input class="form-check-input" type="checkbox" name="mq1" id="mq1" value="mq1">
              <label class="form-check-label" for="mq1">Allow members to send me private messages</label>
          </div>
          <div class="form-check form-check">
              <input class="form-check-input" type="checkbox" name="mq1" id="mq1" value="mq1">
              <label class="form-check-label" for="mq1">Allow doctor to send me queries about cases</label>
          </div>
          <div class="form-check form-check">
              <input class="form-check-input" type="checkbox" name="mq2" id="mq2" value="mq2">
              <label class="form-check-label" for="mq1">Send notifications to my primary mail ID and mobile number</label>
          </div>
      </div>
  </div>
  <div class="row mt-3">
      <div class="col-12">
          <label>Members' queries</label>
      </div>
      <div class="col-12 form-group">
          <div class="form-check form-check">
              <input class="form-check-input" type="checkbox" name="mn1" id="mn1" value="mn1">
              <label class="form-check-label" for="mn1">Allow members' healthcare queries to reach me</label>
          </div>
          
      </div>
  </div>
</div>
</template>
<script>
export default {
    data() {
    return {}},
    methods: {
    GoSetting(){
            this.$router.push({ path: `/doctor/mobile-settings` });
            
        },
    }

}
</script>
<style>
@media screen and (max-width:991px) {
    .mobileStyleDoctor{
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom:6rem;
    }
    .messagingMobile {
        margin-left: 20px;
        /* margin-bottom: 10px; */
        margin-top: 10px;
        color: #000;
        font-weight: 550;
    }
}

    </style>

    
<!-- <script lang="js" src='./settings.js'></script> -->